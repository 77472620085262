import { PublishCdmEventsOptions } from '@jarvis/shell-commons/dist/services/AnalyticsService/types';
import { URLS } from './urls';
import { FormTypesEnum } from './enums';

const activity = 'UnifiedAcctMgmt-v01';
export const screenPath = '/ReactMyAccountSecurity/';
export const screenDetailsPath = '/ReactMyAccountSecurity/Security/';
export const screenName = 'Security';
export const screenDetailsName = 'SecurityDetails';
const eventDetailVersion = '1.5.0';

export interface AnalyticsEventPropTypes {
  actionAuxParams?: string;
  action: string;
  activity: string;
  screenPath: string;
  screenName: string;
  screenMode?: string;
  controlDetail?: string;
  version: string;
}

export interface AnalyticsEventWithControlName extends AnalyticsEventPropTypes {
  controlName: string;
}

export const publishEvent = (
  event: AnalyticsEventPropTypes,
  options?: PublishCdmEventsOptions
): void => {
  const publishCdmEvents = (window as any).Shell?.v1.analytics.publishCdmEvents;

  if (!publishCdmEvents) {
    return;
  }

  const dateTime = new Date().toISOString();
  const cdmEvents = {
    dateTime,
    eventDetailType:
      'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
    eventCategory: 'simpleUi',
    eventDetail: event,
    version: '1.5.0'
  };

  publishCdmEvents([cdmEvents], options);
};

const ACTIONS = {
  SCREEN_DISPLAYED: 'ScreenDisplayed',
  CONTROL_BUTTON_CLICKED: 'ControlButtonClicked',
  CONTROL_HYPERLINK_CLICKED: 'ControlHyperLinkClicked',
  MODULE_DISPLAYED: 'ModuleDisplayed'
};

export const SecurityScreenDisplayed: AnalyticsEventPropTypes = {
  action: ACTIONS.SCREEN_DISPLAYED,
  activity,
  screenPath,
  screenName,
  version: eventDetailVersion
};

export const SecurityDetailsScreenDisplayed: AnalyticsEventPropTypes = {
  action: ACTIONS.SCREEN_DISPLAYED,
  activity,
  screenPath: screenDetailsPath,
  screenName: screenDetailsName,
  version: eventDetailVersion
};

export const ManageLoginDetailsHyperLinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ManageLoginDetailsLink',
    controlDetail: URLS.HpIdSecurity,
    version: eventDetailVersion
  };

export const ManageEmailHyperLinkClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ManageEmailLink',
  controlDetail: URLS.HpIdSecurity,
  version: eventDetailVersion
};

export const ManageEmailControlButtonClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ManageEmailButton',
  actionAuxParams: `type=${FormTypesEnum.Email}`,
  version: eventDetailVersion
};

export const ManagePhoneHyperLinkClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ManagePhoneLink',
  controlDetail: URLS.HpIdSecurity,
  version: eventDetailVersion
};

export const ManagePhoneControlButtonClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ManagePhoneButton',
  actionAuxParams: `type=${FormTypesEnum.Phone}`,
  version: eventDetailVersion
};

export const ChangePasswordHyperLinkClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ChangePasswordLink',
  controlDetail: URLS.HpIdSecurity,
  version: eventDetailVersion
};

export const ChangePasswordControlButtonClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ChangePasswordButton',
    actionAuxParams: `type=${FormTypesEnum.Password}`,
    version: eventDetailVersion
  };

export const ManageAdvancedSecurityOptionsHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ManageAdvancedSecurityOptionsLink',
    controlDetail: URLS.HpIdSecurity,
    version: eventDetailVersion
  };

export const MobileTwoFactorAuthHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'MobileTwoFactorAuthLink',
    controlDetail: URLS.MobileTwoFactorAuth,
    version: eventDetailVersion
  };

export const ManageActiveSessionsHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ManageActiveSessionsLink',
    controlDetail: URLS.HpIdSecurity,
    version: eventDetailVersion
  };

export const LoginDetailsError = (
  errorInfo: string
): AnalyticsEventPropTypes => {
  return {
    actionAuxParams: `error=${errorInfo.slice(0, 256)}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath: `${screenPath}Security/`,
    screenName: 'LoginDetailsError',
    version: eventDetailVersion
  };
};
