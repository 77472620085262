import React from 'react';
import { LookingForSomethingElse } from '@hpx-core-experiences/react-my-account-commons/dist/index';
import ActiveSessions from 'src/components/ActiveSessions';
import AdvancedSecurityOptions from 'src/components/AdvancedSecurityOptions';
import LoginDetails from 'src/components/LoginDetails';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { FeatureFlagProps } from 'src/types/FeatureFlag';
import { Container, Content, Header } from './styles';
import { screenName, screenPath } from 'src/utils/analytics';

const Security = ({ flags, flagsLoadingState }: FeatureFlagProps) => {
  const { translate } = useDependencyManagerContext();

  return (
    <Container
      data-testid="security-page"
      aria-label="SecurityPage"
    >
      <Header>
        <h4>{`${translate('menu.security', 'Security')}`}</h4>
        <p className="body">
          {`${translate(
            'security.description',
            'Manage your login details and what devices are used to log in to your HP account'
          )}`}
        </p>
      </Header>
      <Content>
        <LoginDetails
          flags={flags}
          flagsLoadingState={flagsLoadingState}
        />
        <AdvancedSecurityOptions />
        <ActiveSessions />
      </Content>
      {flags.isOriginalFeedbackExperienceEnabled && (
        <LookingForSomethingElse
          analytics={{
            screenName: screenName,
            screenPath: screenPath
          }}
          translate={translate}
        />
      )}
    </Container>
  );
};

export default Security;
